<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    <grid
      item-min-width="200px"
      class="align-top"
      v-if="hasErrors"
    >
      <v-text-field
        v-for="(error, idx) in errors"
        :key="idx"
        class="mx-2"
        :value="error"
        readonly
      />
    </grid>
    <grid
      item-min-width="200px"
      class="align-top overflow-y-auto"
    >
      <div
        v-for="(p, idx) in providers"
        :key="idx"
      >
        <v-card class="pa-4 d-flex overflow-x-auto">
          <div class="d-flex flex-column">
            <document-name-ref
              class="mb-2"
              :id="p.id"
              :cache-type="cacheTypeStatusDispute"
            />
            <div v-if="p.connectFailed">
              <span
                class="error--text"
                readonly
              >{{adaptConnectFailed(p.connectFailed)}}</span>
            </div>
            <div
              class="caption"
              v-else
            >
              <span readonly>{{computedDurationLabel}}<br /></span>
              <span
                class="duration mr-4"
                readonly
              >{{p.dur}}</span>
            </div>
          </div>
        </v-card>
      </div>
    </grid>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'

export default {
  name: 'incoming-message',
  created () {
    this.$store.commit('setModule', {
      name: this.$t('t.IncomingMessageRun')
    })
  },
  components: {
    Grid: () => import('@/components/grid'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    computedDurationLabel () {
      return `${this.$t('t.Duration')} (ms)`
    },
    hasErrors () {
      return this.data?.errors.length
    },
    errors () {
      return this.data?.errors
    },
    providers () {
      return this.data?.providers
    }
  },
  data () {
    return {
      data: null,
      cacheTypeStatusDispute: CacheType.ProviderRef
    }
  },
  methods: {
    adaptConnectFailed (m) {
      return m?.message
    }
  },
  async mounted () {
    const url = '/core/run/recap'
    const r = await this.$http().get(`${url}/${this.$route.params.id}`)
    this.data = r.data
  }
}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.v-text-field
  min-width 250px

.duration
  width 100px
</style>
